import ApiService from './api-service';
import { CONVERSATION_TYPE } from 'types';
import { TemplateContainer } from 'utils/customSelectors/templates/useTemplateController';

export class TemplateService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getTemplateUrl(type: CONVERSATION_TYPE): string {
    return type === CONVERSATION_TYPE.EMAIL ? `email/v1/` : `messaging/v1/Sms/`;
  }

  getTemplateContainers(type: CONVERSATION_TYPE): Promise<TemplateContainer[]> {
    return this.apiService.get(`${this.getTemplateUrl(type)}templateContainers`).then((r) => r?.data);
  }

  getTemplateDetails(type: CONVERSATION_TYPE, templateContainer: string, templateName: string): Promise<string> {
    const containerParam = this.apiService.encodeQueryParam('templateContainer', templateContainer);
    const templateParam = this.apiService.encodeQueryParam('templateName', templateName);

    return this.apiService.get(`${this.getTemplateUrl(type)}template?${containerParam}&${templateParam}`).then((r) => r?.data);
  }
}

export default TemplateService;
