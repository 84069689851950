import { AxiosResponse, AxiosError } from 'axios';
import { createAxiosInstance } from '../axios';
import settings from 'constants/appsettings.json';
import { IRequestConfig } from 'types';
import { msalInstance } from 'contexts/Provider';
import { getAuthToken } from 'services/Auth/Auth.service';
import * as uuid from 'uuid';
import { store } from 'store';

const serviceScope = (url: string) => {
  if (url.indexOf('/v1/Task') !== -1 || url.indexOf('/v1/Lookup') !== -1 || url.indexOf('tasks/v1/Conversation') !== -1) {
    return settings.serviceScopes.TaskService;
  }
  if (url.indexOf('chat') !== -1 || url.indexOf('chatMessage') !== -1) {
    return settings.serviceScopes.ChatService;
  }
  if (url.toLowerCase().indexOf('messaging/v1') !== -1) {
    return settings.serviceScopes.MessagingService;
  }
  if (url.toLowerCase().indexOf('email/v1') !== -1) {
    return settings.serviceScopes.EmailService;
  }

  if (url.toLowerCase().indexOf('labs') !== -1) {
    return settings.serviceScopes.LabsService;
  }
  if (url.toLowerCase().indexOf('activity') !== -1) {
    return settings.serviceScopes.ActivityService;
  }
  if (url.toLowerCase().indexOf('member/v1') !== -1) {
    return settings.serviceScopes.MemberService;
  }
  if (url.toLowerCase().indexOf('referral') !== -1) {
    return settings.serviceScopes.ReferralService;
  }
  if (url.toLowerCase().indexOf('encounter') !== -1) {
    return settings.serviceScopes.EncounterService;
  }
  if (url.toLowerCase().indexOf('appointment') !== -1) {
    return settings.serviceScopes.AppointmentService;
  }
  return settings.serviceScopes.OrgService;
};

const axiosClient = async (url: string) => {
  const accounts = msalInstance.getAllAccounts();
  if (!accounts || accounts.length === 0) {
    return null;
  }
  const account = accounts[0];

  const correlationId = uuid.v4();
  const messageId = uuid.v4();
  const authToken = await getAuthToken(msalInstance, account, serviceScope(url));
  const baseURL = settings.apiDetails.baseUri;

  return createAxiosInstance(store.dispatch, baseURL, authToken, correlationId, messageId);
};

const apiData = () => {
  return {
    processRequest: async (config: IRequestConfig) => {
      const axReq = await axiosClient(config.url);
      if (axReq) {
        const { url, data, onResolve, onReject, method = 'get' } = config;

        const params = {
          referrerPolicy: 'origin',
          credentials: 'include',
          url,
          data,
          method
        };

        let returnData: any;
        await axReq
          .request(params)
          .then((res: AxiosResponse) => {
            if (res.status !== 200 && res.status !== 201 && res.status !== 202 && res.status !== 204) {
              if (onReject) {
                onReject(res.data);
              }
              return res.data;
            }

            if (onResolve) {
              onResolve(res);
            }
            returnData = res.data;
          })
          .catch((err: AxiosError) => {
            const msg = err.message;
            if (onReject) {
              onReject(err.response);
            }
            returnData = msg;
          });
        return returnData;
      }
    }
  };
};

export default apiData;
